import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Login from '../views/Login.vue'
import UserDashboard from '../views/UserDashboard.vue'
import NewTicket from '../views/NewTicket.vue'
import UploadTest from '../views/UploadTest.vue'
import AllTicket from '../views/AllTicket.vue'
import EditTicket from '../views/EditTicket.vue'
import Register from '../views/Register.vue'
import ViewTicket from '../views/ViewTicket.vue'
import AdminLogin from '../views/AdminLogin.vue'
import AdminDashboard from '../views/AdminDashboard.vue'
import AdminAllTicket from '../views/AdminAllTicket.vue'
import AdminViewTicket from '../views/AdminViewTicket.vue'
import Profile from '../views/Profile.vue'
import Notifications from '../views/Notification.vue'
import Test from '../views/Test.vue'

Vue.use(VueRouter)

const routes = [{
        path: '/',
        name: 'Home',
        component: Home
    },
    {
        path: '/login',
        name: 'Login',
        component: Login
    },
    {
        path: '/userdashboard',
        name: 'UserDashboard',
        component: UserDashboard,
    },
    {
        path: '/newticket',
        name: 'NewTicket',
        component: NewTicket,
    },
    {
        path: '/upload',
        name: 'upload',
        component: UploadTest,
    },
    {
        path: '/allticket',
        name: 'AllTicket',
        component: AllTicket,
    },
    {
        path: '/editticket/:tid',
        name: 'EditTicket',
        component: EditTicket,
    },
    {
        path: '/register',
        name: 'Register',
        component: Register,
    },
    {
        path: '/viewticket/:id',
        name: 'ViewTicket',
        component: ViewTicket,
    },
    {
        path: '/adminlogin/',
        name: 'AdminLogin',
        component: AdminLogin,
    },
    {
        path: '/admindashboard',
        name: 'AdminDashboard',
        component: AdminDashboard,
    },
    {
        path: '/adminallticket',
        name: 'AdminAllTicket',
        component: AdminAllTicket,
    },
    {
        path: '/adminviewticket/:id',
        name: 'AdminViewTicket',
        component: AdminViewTicket,
    },
    {
        path: '/profile',
        name: 'Profile',
        component: Profile,
    },
    {
        path: '/notification',
        name: 'Notification',
        component: Notifications,
    },
    {
        path: '/test',
        name: 'Test',
        component: Test,
    },

]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes: routes
})

export default router